import React from 'react'
import {
  Accordions,
  CardsBackground,
  Cards,
  ContactForm,
  ContentHero,
  CourseMap,
  DefaultForm,
  ExternalRaceHero,
  Gallery,
  Image,
  LinkBlock,
  MarathonHero,
  Menu,
  NewsEvents,
  Partners,
  PartnerTiers,
  DefaultPromo,
  RaceDates,
  RaceIntro,
  RacePromo,
  RaceCollage,
  RightColumnRaceCollage,
  RacesCards,
  RacesList,
  Schedule,
  TopQuestions,
  TwoColumnPromo,
  VideoBackground,
  Wysiwyg,
} from './blocks'

const Block = (props) => {
  const { data, colors, mapboxToken, newsEventsBackgroundFallback, site } = props

  const renderBlock = () => {
    switch (data.cell_action) {
      case 'accordions':
        return <Accordions block={data} colors={colors} />
      case 'cards':
        return <Cards block={data} colors={colors} />
      case 'cards_background':
        return <CardsBackground block={data} colors={colors} />
      case 'contact_form':
        return (
          <ContactForm
            block={data}
            primary={colors.primary}
            tertiary={colors.tertiary}
            linkBlockBackground={colors.linkBlockBackground}
          />
        )
      case 'content_hero':
        return (
          <ContentHero
            block={data}
            linkColor={colors.linkColor}
            background={colors.secondary}
          />
        )
      case 'course_map':
        return <CourseMap block={data} mapboxToken={mapboxToken} />
      case 'form':
        return (
          <DefaultForm
            block={data}
            primary={colors.primary}
            linkBlockBackground={colors.linkBlockBackground}
          />
        )
      case 'gallery':
        return <Gallery block={data} color={colors.secondary} site={site} />
      case 'image':
        return <Image block={data} />
      case 'link':
        return (
          <LinkBlock
            block={data}
            background={colors.primary}
            linkBlockBackground={colors.linkBlockBackground}
            linkColor={colors.linkColor}
            color={colors.button}
          />
        )
      case 'marathon_hero':
        return <MarathonHero block={data} background={colors.primary} />
      case 'menu':
        return (
          <Menu
            block={data}
            background={
              colors.menuBackground ? colors.primary : colors.tertiary
            }
            darkText={colors.darkText}
          />
        )
      case 'partners':
        return <Partners block={data} />
      case 'partner_tiers':
        return <PartnerTiers block={data} colors={colors} />
      case 'race_dates':
        return <RaceDates block={data} background={colors.secondary} />
      case 'race_intro':
        return <RaceIntro block={data} colors={colors} />
      case 'race_promo':
        return <RacePromo block={data} background={colors.tertiary} />
      case 'race_hero':
        return <ExternalRaceHero block={data} colors={colors} />
      case 'race_news_events':
        return (
          <NewsEvents
            block={data}
            colors={colors}
            newsEventsBackgroundFallback={newsEventsBackgroundFallback}
          />
        )
      case 'races_list':
        return <RacesList block={data} colors={colors} site={site} />
      case 'default_promo':
        return <DefaultPromo block={data} colors={colors} />
      case 'races_cards':
        return <RacesCards block={data} colors={colors} site={site} />
      case 'schedule':
        return <Schedule block={data} border={colors.tertiary} />
      case 'top_questions':
        return (
          <TopQuestions
            block={data}
            background={colors.tertiary}
            darkText={colors.darkText}
          />
        )
      case 'two_column_promo':
        return <TwoColumnPromo block={data} colors={colors} />
      case 'video_background':
        return <VideoBackground block={data} />
      case 'wysiwyg':
        return (
          <Wysiwyg
            block={data}
            links={colors.tertiary}
            primary={colors.primary}
            secondary={colors.secondary}
          />
        )
      case 'race_collage':
        return <RaceCollage block={data} site={site} />
      case 'right_column_race_collage':
        return <RightColumnRaceCollage block={data} site={site} />
      default:
        return null
    }
  }

  return <React.Fragment>{renderBlock()}</React.Fragment>
}

export default Block
